<template>
<div>

    <b-row>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-edit fa-md"></i> <span class="h5"> Por favor, seleccione el riesgo antisoborno que desea evaluar:</span>
                </CCardHeader>
                <CCardBody>
                    <validation-observer ref="observer" v-slot="{ handleSubmit }">
                        <b-form @submit.stop.prevent="handleSubmit(nuevaEvaluacion)">
                            <b-row>
                                <!-- <b-col md="12">
                                    <b-button v-if="!//" variant="dark" class="float-right mr-2" size="sm" @click="selectAllRows"><i class="fas fa-check-square"></i> Seleccionar todos</b-button>
                                    <b-button v-else-if="//" variant="dark" class="float-right mr-2" size="sm" @click="clearSelected"><i class="far fa-square"></i> Limpiar selección</b-button>
                                </b-col> -->
                                <b-col md="12" class="mt-3">
                                    <b-table :items="listaRiesgos" :fields="cabecera" ref="selectableTable" selectable select-mode="single" @row-selected="onRowSelected" class="table-custom" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                                        <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                        <template #cell(selected)="{ rowSelected }">
                                            <template v-if="rowSelected">
                                                <span aria-hidden="true">&check;</span>
                                                <span class="sr-only">Seleccionado</span>
                                            </template>
                                            <template v-else>
                                                <span aria-hidden="true">&nbsp;</span>
                                                <span class="sr-only">No seleccionado</span>
                                            </template>
                                        </template>
                                        <!-- <template #cell(opciones)="param">
                                            <b-button v-if="param.item.idEvaluacionDetalleRiesgo!=''" @click="eliminarRiesgoDetalle(param)" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                            </b-button>
                                        </template> -->
                                        <template #cell(tipo)="param">
                                            <span v-if="param.item.tipo >= 0 && param.item.tipo <= 6">Bajo</span>
                                            <span v-else-if="param.item.tipo > 6 && param.item.tipo < 16">Medio</span>
                                            <span v-else-if="param.item.tipo >= 16">Alto</span>
                                        </template>
                                    </b-table>
                                    <!-- {{arrayRiesgos}} -->
                                </b-col>
                            </b-row>

                            <b-col class="my-2 text-center" md="12">
                                <b-button class="mr-2" :to="{name: 'Evaluación de riesgos antisoborno'}" variant="dark">
                                    <i class="fas fa-arrow-left"></i> Volver
                                </b-button>
                                <b-button v-if="$route.params.id!=null" v-show="arrayRiesgos.length>0" :to="{name: 'Gestión evaluación riesgos antisoborno', params: {id: $route.params.id, arrayRiesgos: arrayRiesgos}}" variant="success" class=" mr-2">
                                    <i class="fas fa-arrow-right"></i> Continuar
                                </b-button>
                                <b-button v-if="$route.params.id==null" v-show="arrayRiesgos.length>0" :to="{name: 'Gestión evaluación riesgos antisoborno', params: {arrayRiesgos: arrayRiesgos}}" variant="success" class=" mr-2">
                                    <i class="fas fa-arrow-right"></i> Continuar
                                </b-button>

                            </b-col>
                        </b-form>
                    </validation-observer>
                </CCardBody>
            </CCard>
        </b-col>
    </b-row>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    components: {

    },
    data() {
        return {
            //BtnMostrar: false,
            cabecera: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "nombre",
                    label: "Riesgo",
                    class: "text-center",
                },
                {
                    key: "tipo",
                    label: "Tipo",
                    class: "text-center",
                },
                {
                    key: "selected",
                    label: "Selección",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                }
            ],
            listaRiesgos: [],
            arrayRiesgos: [],
            datosSession: {
                idCliente: ''
            },
        }
    },
    methods: {
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onRowSelected(items) {

            this.arrayRiesgos = items;
        },
        /* selectAllRows() {
            this.$refs.selectableTable.selectAllRows()
            this.// = !this.//;
        },
        clearSelected() {
            this.$refs.selectableTable.clearSelected()
            this.// = !this.//;
        }, */
        listarRiesgos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-riesgos-antisoborno-sga", {
                        params: {
                            idCliente: me.datosSession.idCliente,
                            ejercicio: JSON.parse(localStorage.settings).yExcs,
                        },
                    }
                )
                .then(function (response) {
                    //me.listaRiesgos = response.data

                    for (const i in response.data.filter(x => x.tipo > 6)) {
                        me.listaRiesgos.push({
                            idEvaluacion: '',
                            idRiesgo: response.data[i].idRiesgo,
                            nombre: response.data[i].nombre,
                            idControl: response.data[i].idControl,
                            idSubControl: response.data[i].idSubControl,
                            probabilidad: response.data[i].probabilidad,
                            impacto: response.data[i].impacto,
                            tipo: response.data[i].tipo,
                            fechaSeguimiento: '',
                            clasificacionSig: '',
                            fueEficaz: '',
                            descripcionObservacion: '',
                            listaGestiones: [{
                                fechaGestion: '',
                                listaMedidas: [{
                                    idPuestoTrabajo: '',
                                    idEmpleado: '',
                                    accionesTexto: '',
                                    evidencias: '',
                                    fechaProgramada: '',
                                    estado: 2,
                                }],
                                /* documentacion: null,
                                urlDocumentacion: '',
                                nombreDocumentacion: '', */
                                estado: 2,
                            }],
                        });
                    }
                    //console.log(response.data.filter(x => x.tipo>6))
                    if (me.$route.params.id != null) {
                        me.consultarEvaluacionRiesgo();
                    }
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        consultarEvaluacionRiesgo() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/obtener-evaluacion-riesgo-antisoborno-sga", {
                        params: {
                            idEvaluacion: me.$route.params.id,
                        },
                    }
                )
                .then(function (response) {
                    //console.log(response.data)
                    for (const j in me.listaRiesgos) {
                        if (me.listaRiesgos[j].idRiesgo == JSON.parse(response.data[0].datosAcordionRiesgo).idRiesgo) {
                            me.$refs.selectableTable.selectRow(parseInt(j))
                        }
                    }
                    //me.listaRiesgos.filter(x => x.idRiesgo==JSON.parse(response.data[0].datosAcordionRiesgo).idRiesgo)
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        eliminarRiesgoDetalle(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el riesgo?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-evaluacion-detalle-riesgo", {
                            idEvaluacionDetalleRiesgo: param.item.idEvaluacionDetalleRiesgo,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            //me.listarRiesgos();
                            me.listaRiesgos[param.index].idEvaluacionDetalleRiesgo = '';
                            //me.$refs.selectableTable.selectRow(parseInt(j))
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        }
    },
    watch: {

    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            this.listarRiesgos();
        }
    }

}
</script>
